import React from "react"
import IntegrationTemplate from "../../templates/integration"
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="supabase"
    name="Supabase"
    shortDescription="Store your backups on Supabase Object Storage!"
    website={"https://supabase.com/storage"}
    links={[{text: 'Documentation', url: 'https://docs.simplebackups.com/storage-providers/ghsg5GE15AMwMo1qFjUCXn/supabase/bYZu29yq5eeqk4uy7a4QyC'}]}
    category="Storage"
    logo="supabase-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Supabase')}
    metaDescription={meta.description.replace('##provider##', 'Supabase')}
  >
    <IntegrationStorageContent
      keyRef="supabase"
      service="Supabase"
      provider="Supabase"
      learnTags={['supabase']}
    />
  </IntegrationTemplate>
)

export default ContentPage
